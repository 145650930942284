import React from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { window } from 'browser-monads'
import PreviewTemplate from '../templates/previewTemplate'

const PreviewPage = () => {
  const isPreview = window.location.pathname.includes('/preview')
  const location = window.location

  if (!!isPreview) {
    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PreviewTemplate location={location} />
      </React.Fragment>
    )
  } else {
    navigate('/');
    return null
  }
}

export default PreviewPage
