import React from 'react'

const VerticalRow = ({ images }) => {
    return (
      <React.Fragment>
        <img className="wedding--image-vertical1" src={images[0]} alt="agba" />
        <img className="wedding--image-vertical2" src={images[1]} alt="agba" />
      </React.Fragment>
    )
  }

export default VerticalRow
