import React, { Component } from 'react'
import * as queryString from 'query-string'
import {subscribeToData, fetchDataFromSanity, builder} from '../helpers/sanityHelpers'
import PreviewHorizontalRow from '../components/previewHorizontalRow'
import PreviewVerticalRow from '../components/previewVerticalRow'

export class PreviewTemplate extends Component {
  state={
    isLoading: true,
    data: undefined
  }

  componentDidMount() {
    const
      query = queryString.parse(this.props.location.search),
      { weddingId, isDraft } = query;

    this.fetchData();

    if (isDraft) {
      subscribeToData(weddingId, this.fetchData);
    }

  }

  fetchData = async () => {
    const
      query = queryString.parse(this.props.location.search),
      { weddingId, isDraft } = query;

    fetchDataFromSanity(weddingId, isDraft)
      .then(res => this.setState({
        isLoading: false,
        data: res[0]
      }))
      .catch(() => this.setState({isLoading:false}))

  };

  urlFor = (source) => {
    return builder.image(source)
  }

  render() {
    const
      {isLoading, data} = this.state;

    if (isLoading && !data) {

      return (
        <div id="success">
          <h1>LOADING</h1>
          <p>Wait, please...</p>
        </div>
      )

    } else {
      const {title, subtitle, rowsGallery, planner, plannerUrl, venue, date} = this.state.data;

      return(
        <div id="wedding">
          <h1>{!!title && title}</h1>
          <p className="wedding--subtitle">{!!subtitle && subtitle}</p>

          {!!rowsGallery && rowsGallery.map((row, key) => {
            const images = row.imagesGallery.map(image => this.urlFor(image).url())
            return (
              images.length === 1 ?
              <PreviewHorizontalRow key={key} title={!!title && title} image={images[0]}/>
              :
              <PreviewVerticalRow key={key} title={!!title && title} images={images}/>
            )
          })}

          <div className="wedding--info-container">
            <div>
              <p>Wedding planner: <a href={!!plannerUrl && plannerUrl} target="__blank">{!!planner && planner}</a></p>
              <p>Venue: {!!venue && venue}</p>
              <p>Date: {!!date && date}</p>
            </div>
          </div>
        </div>

      )
    }
  }
}

export default PreviewTemplate
